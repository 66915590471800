/*
 * @Author: qiheng
 * @Date: 2021-07-09 10:06:32
 * @LastEditors: qiheng
 * @LastEditTime: 2021-07-19 10:30:06
 */
import request from '@/utils/request'
export function pageOrderlist(pageCurrent, pageSize, projectName, projectId, displayStatus, startCreateDate, endCreateDate, schoolCode) {
  return request({
    url: '/pay/web/order/pageOrderlist',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    },
    transformRequest: [function(data) {
      data = JSON.stringify(data)
      return data
    }],
    data: {
      'pageCurrent': pageCurrent,
      'pageSize': pageSize,
      'projectName': projectName,
      'projectId': projectId,
      'displayStatus': displayStatus,
      'startCreateDate': startCreateDate,
      'endCreateDate': endCreateDate,
      'schoolCode': schoolCode
    }
  })
}
export function getOrderById(id) {
  return request({
    url: 'pay/pay/orderTrade/getOrderById/' + id,
    method: 'get'
  })
}
export function queryOrderById(id) {
  return request({
    url: 'pay/web/order/queryOrderById/' + id,
    method: 'post'
  })
}
export function getOrderByOrderNo(schoolCode, orderNo) {
  return request({
    url: 'pay/pay/orderTrade/getOrderByOrderNo/' + schoolCode + '/' + orderNo,
    method: 'get'
  })
}
export function closeOrderById(id) {
  return request({
    url: 'pay/web/order/closeOrderById/' + id,
    method: 'post'
  })
}
export function queryOrderStatusCanPay(id) {
  return request({
    url: 'pay/web/order/queryOrderStatusCanPay/' + id,
    method: 'post'
  })
}
