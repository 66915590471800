<!-- orderDetail -->
<template>
  <div id="orderDetail">
    <div>
      <!-- 头部地理位置和搜索框 -->
      <div class="head">
        <!-- 顶部NavBar 开始 -->
        <van-nav-bar
          :title="$t('orderDetail.title')"
          :left-text="$t('main.return')"
          left-arrow
          style="background-color: rgba(255,255,255,0);"
          @click-left="onClickLeft"
        />
      </div>
    </div>
    <div class="outboxstyle">
      <div class="boxstyle">
        <p class="p1 border-left">
          <img src="@/assets/new_icon/order.svg" class="icon">
          {{ $t('orderDetail.orderInformation') }}
        </p>
        <van-cell-group>
          <van-cell :title="$t('orderDetail.orderNumber')" :value="order.orderNo" />
          <van-cell :title="$t('orderDetail.submissionTime')" :value="order.createDate" label="" />
          <van-cell :value="'￥'+fenToYuan(order.amount)" class="red">
            <template #title>
              <span class="custom-title">{{ $t('orderDetail.orderAmount') }}</span>
            </template>
          </van-cell>
        </van-cell-group>
      </div>
      <img :src="imageL[0]" style="width: 93.5%;position: absolute;">
      <div class="boxstylemoney">
        <p class="p1 border-left">
          <img src="@/assets/new_icon/pay-message.svg" class="icon">
          {{ $t('orderDetail.paymentInformation') }}
        </p>
        <van-cell-group>
          <van-cell :title="$t('orderDetail.paymentTime')" :value="order.actualCloseTime" />
          <van-cell :title="$t('orderDetail.paymentMethod')" :value="formatterOrderTradeChannel(order.tradeChannel)" label="" />
        </van-cell-group>
      </div>
      <img :src="imageL[0]" style="width: 100%; margin:0 auto" class="img">
      <!-- 只有一个项目 -->
      <div v-if="showType === 'meeting'" class="boxstylemoney">
        <van-collapse v-model="activeNames">
          <van-collapse-item name="1">
            <template #title>
              <div class="border-left padding-left-16">
                <img src="@/assets/new_icon/doc.svg" class="icon icon-yellow">
                {{ $t('orderDetail.paymentItems') }}(1/1)
              </div>
            </template>
            <van-cell-group>
              <!-- 项目名称必有 -->
              <van-cell :title="$t('orderDetail.entryName')" :value="order.projectName" />
              <van-cell :title="$t('commonPay.payAmount')" :value="'￥'+fenToYuan(order.amount)" class="red" />
              <!-- 其他循环 -->
              <!-- updateby yuxin 循环自定义表单fromData的key和value -->
              <div
                v-for="(item, name, index) in detailList"
                :key="index"
              >
                <van-cell
                  v-for="(item2, index2) in item.fromData"
                  :key="index2"
                  :title="index2"
                  :value="item2"
                />
              </div>
            </van-cell-group>
          </van-collapse-item>
        </van-collapse>
      </div>
      <!-- 不止一个 -->
      <div v-if="showType === 'nameList'" class="boxstylemoney">
        <van-collapse v-model="activeNames">
          <div
            v-for="(item,index) in rightDataList"
            :key="item.id"
          >
            <van-cell-group>
              <van-collapse-item>
                <template #title>
                  <div class="border-left padding-left-16">
                    <img src="@/assets/new_icon/doc.svg" class="icon icon-yellow">
                    {{ $t('orderDetail.paymentItems') }}{{ `(${index+1}/${rightDataList.length})` }}
                  </div>
                </template>
                <van-cell-group>
                  <van-cell :title="$t('orderDetail.entryName')" :value="item.projectName" />
                  <van-cell :title="$t('commonPay.payAmount')" :value="'￥'+fenToYuan(item.actualAmount)" class="red" />
                </van-cell-group>
              </van-collapse-item>
            </van-cell-group></div>
        </van-collapse>
      </div>
    </div>
    <div class="bottom">
      <van-button
        v-if="tempData.status === 'PENDING_PAYMENT'"
        type="default"
        size="large"
        class="bottombutton close-btn"
        @click="closeOrder"
      >{{ $t('orderDetail.closeOrder') }}</van-button>
      <van-button v-if="tempData.displayStatus === '101'" type="info" size="large" class="bottombutton" @click="dealwith">{{ $t('orderDetail.toPay') }}</van-button>
    </div>
    <!-- 回到顶部按钮 -->
    <v-top />
  </div>
</template>
<script>
// import store from '@/store'
import { closeOrderById, getOrderById, queryOrderStatusCanPay
} from '@/api/orderlist'
import { getChannelList } from '@/api/auditOp.js'
import { fenToYuan } from '@/utils/common'
import { getOrderTradeDetailByIds
} from '@/api/meeting'
import { Toast } from 'vant'
export default {
  name: 'OrderDetail',
  components: {
    // ReiTabbar
  },
  data() {
    return {
      tempData: {
        displayStatus: '',
        status: '',
        payBizInfoVOList: [],
        id: '',
        projectId: ''
      },
      showType: '',
      rightDataList: [],
      detailList: [],
      shopList: [
        {}
      ],
      // 支付方式
      channelList: [],
      order: {},
      activeNames: ['1'],
      imageL: [
        require('../../assets/newImages/line.png')
      ]
    }
  },
  beforeCreate() {
    document.querySelector('body').setAttribute('style', 'background: #F5F5F5')
  },

  mounted() {
    this.init()
    // 上半部数据
    this.leftData()
    // 获取支付方式
    if (this.$route.query.info !== undefined) {
      this.showType = 'nameList'
    } else if (this.$route.query.order !== undefined) {
      this.showType = 'meeting'
    }
    // this.showType = 'nameList'
    if (this.$route.query.info) {
      this.rightDataList = JSON.parse(this.$route.query.info)
    }
    this.getChannelList()
    if (this.$route.query.info) {
      this.rightDataList = JSON.parse(this.$route.query.info)
    }
  },
  methods: {
    init() {
      this.queryDetail()

      // store.dispatch('user/getInfo').then(res => { // 拉取user_info
      //   this.user.name = res.name
      //   this.user.idserial = res.idserial
      //   this.getList()
      //   this.queryDetail()
      // }).catch((err) => {
      //   console.log(err)
      //   store.dispatch('user/logout').then(() => {
      //     location.reload() // 为了重新实例化vue-router对象 避免bug
      //   })
      // })
    },
    // 取项目
    queryDetail() {
      getOrderTradeDetailByIds(this.$route.query.order).then((response) => {
        // if (this.user.name === '') {
        //   this.$router.push({ name: 'login' })
        //   console.log('登出')
        //   return false
        // }
        this.detailList = response.data
        // const str = this.detailList[0].assemblyList[0].otherData
        // const strObj = JSON.parse(str)
        // const newList = this.detailList[0].formAssemblyVoList
        // for (const i in newList) {
        //   for (const key in strObj) {
        //     if (newList[i].assemblyKey === key) {
        //       this.$set(newList[i], 'formAssemblyValue', strObj[key])
        //     }
        //   }
        // }
        // 只一层的时候
        // if (response.data.length <= 1) {
        //   this.detailList = response.data
        //   const str = this.detailList[0].assemblyList[0].otherData
        //   const strObj = JSON.parse(str)
        //   const newList = this.detailList[0].formAssemblyVoList
        //   for (const i in newList) {
        //     for (const key in strObj) {
        //       if (newList[i].assemblyKey === key) {
        //         this.$set(newList[i], 'formAssemblyValue', strObj[key])
        //       }
        //     }
        //   }
        // } else if (response.data.length > 1) {
        //   this.detailList = response.data
        //   const str = this.detailList[0].assemblyList[0].otherData
        //   const strObj = JSON.parse(str)
        //   const newList = this.detailList[0].formAssemblyVoList
        //   for (const i in newList) {
        //     for (const key in strObj) {
        //       if (newList[i].assemblyKey === key) {
        //         this.$set(newList[i], 'formAssemblyValue', strObj[key])
        //       }
        //     }
        //   }
        // }
      }).catch(err => {
        Toast.fail(err.message)
      })
    },
    fenToYuan(val) {
      return fenToYuan(val)
    },
    // 返回
    onClickLeft() {
      this.$router.push({ path: '/orderList' })
    },
    leftData() {
      const leftData = JSON.parse(this.$route.query.order)
      // 操作信息
      this.tempData.displayStatus = leftData.displayStatus
      this.tempData.status = leftData.status
      this.tempData.payBizInfoVOList = leftData.payBizInfoVOList
      this.tempData.id = leftData.id
      this.tempData.projectId = leftData.projectId
      // console.log('leftData', leftData)
      // 左侧展示信息
      this.$set(this.order, 'orderNo', leftData.orderNo)
      this.$set(this.order, 'createDate', leftData.createDate)
      this.$set(this.order, 'displayStatus', leftData.displayStatus)
      this.$set(this.order, 'amount', leftData.amount)
      this.$set(this.order, 'actualCloseTime', leftData.actualCloseTime)
      this.$set(this.order, 'tradeChannel', leftData.tradeChannel)
      this.$set(this.order, 'payableAmount', leftData.payableAmount)
      // 右侧
      this.$set(this.order, 'projectName', leftData.projectName)
      // this.order.orderNo = this.$route.query.order.orderNo
      // this.order.createDate = this.$route.query.order.createDate
      // this.order.displayStatus = this.$route.query.order.displayStatus
      // this.order.amount = this.$route.query.order.amount
    },
    // 支付方式格式化
    formatterOrderTradeChannel(tradeChannel, column) {
      // console.log('tradeChannel', tradeChannel)
      for (var i = 0; i < this.channelList.length; i++) {
        if ((tradeChannel + '') === this.channelList[i].code) {
          return this.channelList[i].name
        }
      }
    },
    // 支付状态
    getChannelList() {
      const projectVO = {}
      projectVO.schoolCode = window.localStorage.getItem('schoolcode')
      projectVO.partnerId = this.$store.state.settings.partnerId
      getChannelList(projectVO).then(response => {
        this.channelList = response.data
        // console.log('channelList', response.data)
      }).catch(err => {
        Toast.fail(err.message)
      })
    },
    // 带参数跳转页面
    dealwith() {
      // this.$router.push({
      //   path: '/person'
      // })
      queryOrderStatusCanPay(this.tempData.id).then(response => {
        if (response.messageCode !== '0') {
          Toast.fail(response.message)
          this.$router.push('/orderList')
        } else {
          getOrderById(this.tempData.id).then(response => {
            this.$router.push({ path: '/person', query: { orderId: this.tempData.id, projectId: this.tempData.projectId }})
          }).catch(err => {
            Toast.fail(err.message)
          })
        }
      }).catch((err) => {
        this.queryDetail()
        console.log(err)
      })
    },
    // 关闭订单
    closeOrder() {
      closeOrderById(this.tempData.id).then(response => {
        Toast.success(response.message)
        this.$router.push({ path: '/orderList' })
      }).catch(err => {
        Toast.fail(err.message)
      })
    }
  }
}
</script>
<style scoped lang="scss" scope>
  .van-cell__label{
    width: 3rem;
  }
.dashed{
  transform: translate(-2px, 0px);
    width: 101% !important;
}
.boxstylemoney {
    .p1{
      margin-top: 30px;
    }
}
.red{
    .van-cell__value{
        color:#F86262;
        span{
            color: #F86262;
        }
    }
}
#orderDetail {
  position: relative;
  background-color: rgb(245 245 245);
  .head {
    height: 100px;
    width: 100%;
    background:linear-gradient(135deg,rgba(55,147,229,1) 0%,rgba(61,105,199,1) 100%);
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }
}
.outboxstyle {
  padding: 10px 12px;
  background-color: #F5F5F5;
  border-radius: 10px;
  margin-top: -20px;
  width: 100%;
  height: 100%;
  .p1{
    font-size: 14px;
    color:#2E2E2E;
  }
  .padding-left-16{
    margin-top:6px;
    height: 14px;
    line-height: 14px;
  }
 .van-cell__right-icon{
    height: 14px !important;
    top: -6px;
    position: relative

  }
}
.boxstyle {
  margin: 10px 0 0;
  background-color: #ffffff;
  border: 1px white solid;
  border-radius: 10px 10px 0 0;
  p{
    font-size:16px;
    font-weight:700;
    margin: 20px 20px 10px 14px;
  }
}
.boxstylemoney{
  background-color: #ffffff;
  border: 1px white solid;
  border-radius: 0px;
  padding-bottom: 10px;
  p{
    font-size:16px;
    font-weight:700;
    margin: 20px 20px 10px 16px;
  }
}
.bottombutton{
  margin: 5px 0;
}
.bottom{
  margin:0 12px;
  overflow: hidden;
}
.van-cell__value{
  text-align: left;
}
.van-cell__title{
  width: 90px;
}
.van-cell:not(:last-child)::after{
  border-bottom: 0;
}
::after {
  display: contents;
}
.icon{
  width: 22px;
  filter: drop-shadow(#2F9FF2 100px 0);
  transform: translateX(-100px);
}
.icon-yellow{
  filter: drop-shadow(#21C69F 100px 0);
}
.border-left{
  display: flex;
  align-items: center;
}
.boxstylemoney:last-child{
  border-radius: 0 0 10px 10px;
}
.van-cell{
  padding-top: 0;
}
</style>
<style lang="scss">
#orderDetail{
  .van-nav-bar .van-icon {
      color: #ffffff;
  }
  .van-nav-bar__title {
      color: #ffffff;
  }
  .van-nav-bar__text {
      color: #ffffff;
  }
  .van-collapse-item__content{
    padding: 0;
 }
 .van-cell__value{
   flex:2
 }
 [class*='van-hairline']::after {
    border: none;
  }
  .van-nav-bar {
    height: 80px;
  }
  .van-cell__title{
    color:#747474;
  }
  .van-cell__value{
    color: #2E2E2E;
  }
  .van-collapse-item__title .van-cell__title{
    color:#2E2E2E;
    font-weight: bold;
  }
}
.close-btn{
  background-color: rgba(255, 255, 255, 0.6);
  border: 1px solid rgba(59, 164, 242, 0.5);
  color: #2F9FF2;
}
</style>
