import request from '@/utils/request'

export function auditPass(data) {
  return request({
    url: 'pay/audit/auditPass/',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    },
    transformRequest: [function(data) {
      data = JSON.stringify(data)
      return data
    }],
    data
  })
}
export function confusePass(data) {
  return request({
    url: 'pay/audit/confusePass/',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    },
    transformRequest: [function(data) {
      data = JSON.stringify(data)
      return data
    }],
    data
  })
}
export function getChannelList(data) {
  return request({
    url: 'pay/channel/getChannelList',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    },
    transformRequest: [function(data) {
      data = JSON.stringify(data)
      return data
    }],
    data
  })
}
export function getAccountListByChannelId(schoolCode, channelId) {
  return request({
    url: 'pay/channel/getAccountListByChannelId',
    method: 'get',
    headers: {
      'Content-Type': 'application/json'
    },
    params: {
      'schoolCode': schoolCode,
      'channelId': channelId
    }
  })
}
