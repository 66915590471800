import request from '@/utils/request'

export function createOrder(payProjectVO) {
  return request({
    url: 'pay/web/meeting/createMeetingOrder',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    },
    transformRequest: [function(data) {
      data = JSON.stringify(data)
      return data
    }],
    data: {
      'payProjectVO': payProjectVO
    }
  })
}
export function getOrderTradeDetailByIds(data) {
  return request({
    url: 'pay/pay/orderTrade/getOrderTradeDetailByIds',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    },
    data
  })
}
export function queryDetail(id) {
  return request({
    url: 'pay/web/meeting/queryDetail/' + id,
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    }
  })
}
export function getRebateAmount(payAmount, projectId) {
  return request({
    url: 'pay/project/getRebateAmount',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    },
    data: {
      'payAmount': payAmount,
      'projectId': projectId
    }
  })
}
